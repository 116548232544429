import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataSendNotifyPlan, NewPlan } from '../store/educations/education-plan/education-plan.interface';
import { deEscapeHtml } from '../../shared/helpers/deEscapeHtml';
import { BaseFilters } from '../store/filters/filters.models';
import { removeEmptyObjectValues } from '../../shared/helpers/other';
import { Observable } from 'rxjs';
import {
  CheckUserCompletePlanModel,
  CreateLessonPayload,
  EducationListCategory,
  EducationListLessonModel,
  LessonModel,
  TrainingLocalesEnum,
  UserListForFilterModel,
} from '../../shared/models/educations-model';
import { IdTitleObject } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class EducationsService {
  url: string = `${this.apiUrl}/educations/lessons`;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  getLessonCategoriesForFilter(): Observable<EducationListCategory[]> {
    return this.http.get<EducationListCategory[]>(`${this.url}/categories/filter`);
  }

  getUserListForFilter(params): Observable<UserListForFilterModel[]> {
    return this.http.get<UserListForFilterModel[]>(`${this.url}/users/filter`, {
      params: {
        ...params,
        search: (deEscapeHtml(params.search || '') as string).trim(),
      },
    });
  }

  getLessonCategories(filters: BaseFilters) {
    let params = {};
    if (filters) {
      params = {
        ...filters,
      };
    }
    return this.http.get(`${this.url}/categories`, { params, observe: 'response' }).pipe(
      map(res => {
        return {
          listOfCategories: res.body,
          totalCategories: +res.headers.get('X-Items-Count'),
        };
      }),
    );
  }

  getLessons(filters) {
    let params = { perPage: 20, page: filters.page };
    if (filters) {
      params = {
        ...params,
        ...filters,
        search: filters.search || null,
      };
      if (filters.categories) {
        params = {
          ...params,
        };
        params['categories[]'] = filters.categories;
        delete params['categories'];
      }
      delete params['query'];
    }
    return this.http.get(this.url, { params: removeEmptyObjectValues(params), observe: 'response' }).pipe(
      map(res => {
        return {
          listLessons: res.body,
          totalLessons: +res.headers.get('X-Items-Count'),
        };
      }),
    );
  }

  createLessons(payload: CreateLessonPayload): Observable<LessonModel> {
    return this.http.post<LessonModel>(this.url, payload);
  }

  createNewCategory(title: string) {
    return this.http.post(`${this.url}/categories`, { title });
  }

  updateCategory(data) {
    return this.http.put(`${this.url}/categories/${data.id}`, { title: data.title });
  }

  getLessonsById(id: string | number): Observable<EducationListLessonModel> {
    return this.http.get<EducationListLessonModel>(`${this.url}/${id}`);
  }

  updateLesson(id: string | number, payload: CreateLessonPayload): Observable<void> {
    return this.http.put<void>(`${this.url}/${id}`, payload);
  }

  deleteLesson(id: string | number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  deleteCategory(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/categories/${id}`);
  }

  confirmSeen(id: string): Observable<EducationListLessonModel> {
    return this.http.post<EducationListLessonModel>(`${this.url}/${id}/confirm`, {});
  }

  getPlanList(filters: BaseFilters) {
    return this.http.get(`${this.url}/plans`, { params: filters as any, observe: 'response' }).pipe(
      map(res => {
        return {
          planList: res.body,
          totalPlans: +res.headers.get('X-Items-Count'),
        };
      }),
    );
  }

  createNewPlan(formCreate: NewPlan) {
    return this.http.post(`${this.url}/plans`, formCreate);
  }

  updatePlan(formCreate: NewPlan, id: string) {
    return this.http.put(`${this.url}/plans/${id}`, formCreate);
  }

  deletePlan(planId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/plans/${planId}`);
  }

  getPlanById(id: string) {
    return this.http.get(`${this.url}/plans/${id}`);
  }

  getPlanDetailsList(filters: BaseFilters, id: string) {
    return this.http.get(`${this.url}/plans/${id}/details`, { params: filters as any, observe: 'response' }).pipe(
      map(res => {
        return {
          planDetailsList: res.body,
          totalPlansDetails: +res.headers.get('X-Items-Count'),
        };
      }),
    );
  }

  sendNotify(dataSend: DataSendNotifyPlan, id: string) {
    return this.http.post(`${this.url}/plans/${id}/notify`, dataSend);
  }

  sendNotifyPlanDetail(
    idPlan: string,
    idPlanDetail: string,
    payload: {
      smsMessage: string;
      sendEmail?: string;
      emailMessage?: string;
    },
  ) {
    return this.http.post(`${this.url}/plans/${idPlan}/notify/${idPlanDetail}`, payload);
  }

  saveLessonPosition(id: string, position: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/v2/lessons/${id}/position`, {
      position,
    });
  }

  checkUserCompletePlan(): Observable<CheckUserCompletePlanModel> {
    return this.http.get<CheckUserCompletePlanModel>(`${this.apiUrl}/v2/lessons/check`);
  }

  updateUserLocaleSetting(locale: TrainingLocalesEnum): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/v2/users/locale/${locale}`, {});
  }

  getFullLessonById(id: string): Observable<EducationListLessonModel> {
    return this.http.get<EducationListLessonModel>(`${this.apiUrl}/v2/lessons/${id}/full`);
  }

  deleteLessonFilesByLessonId(
    lessonId: string,
    files: {
      [key: string]: Array<'lesson' | 'preview'>;
    },
  ): Observable<void> {
    const params = {};
    if (files.English) {
      params['English[]'] = files.English;
    }
    if (files.Spanish) {
      params['Spanish[]'] = files.Spanish;
    }

    return this.http.delete<void>(`${this.url}/${lessonId}/file`, { params });
  }

  getSkillCategoriesList(): Observable<IdTitleObject[]> {
    return this.http.get<IdTitleObject[]>(`${this.url}/plans/skill-categories`);
  }
}
